.nav-effect {
  position: relative;
}
.nav-effect a {
  color: #000000;
  font-weight: 700;
}
.nav-effect a:hover {
  color: #f47008;
}
.nav-effect::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #f47008;
  transform-origin: center center;
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
  border-radius: 3rem;
}

.MuiMenu-paper {
  margin-top: 35px;
}
